


import React from 'react'

export default function Star() {
    return (
        <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 0.971191V8.98512L3.85055 11.3735L6.7011 13.7619L9.55166 11.3735L12.4022 8.98512V0.971191H1Z" stroke="#ff6326 " stroke-linejoin="bevel" />
            <path d="M6.70126 2.96069L7.68512 4.88161L9.8864 5.18953L8.29466 6.68447L8.67064 8.79684L6.70126 7.79968L4.73352 8.79684L5.10786 6.68447L3.51611 5.18953L5.71739 4.88161L6.70126 2.96069Z" stroke="#ff6326 " stroke-linejoin="bevel" />
        </svg>
    )
}
