import React from 'react'

export default function List() {
    return (



        <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.2371 1.0791H1V13.8698H12.2371V1.0791Z" stroke="#ff6326 " stroke-linejoin="bevel" />
            <path d="M4.0647 4.03076H9.17246" stroke="#ff6326 " stroke-miterlimit="10" />
            <path d="M4.0647 5.99854H9.17246" stroke="#ff6326 " stroke-miterlimit="10" />
            <path d="M4.0647 7.96655H9.17246" stroke="#ff6326 " stroke-miterlimit="10" />
            <path d="M4.0647 9.93408H6.1078" stroke="#ff6326 " stroke-miterlimit="10" />
        </svg>

    )
}
