import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import Infographic from '../components/infographic/infographic'
import Layout from '../components/layout'
import Seo from '../components/seo'
import SmallSpacer from '../components/spacer/small-spacer'
import Star from '../icons/news/star'
import Time from '../icons/news/time'
import List from '../icons/news/list'
import ContentContainer from '../components/content-container/content-container'
import { GatsbyImage } from 'gatsby-plugin-image'
import Spacer from '../components/spacer/spacer'
import Title from '../components/title/title'
import TitleList from '../components/title/title-list'
import SquareScalingBanner from '../components/square-scaling-banner/square-scaling-banner'
import ContentContainerInset from '../components/content-container/content-container-inset'
import NewsFeatured from '../components/news/news-featured'
import FadeInView from '../components/fade-inview/fade-inview'
import ReactPlayer from 'react-player'



export default function News({ data: { article, nextArticle } }) {
    const { name, info, category, meta, author, date } = article


    const findTime = useMemo(() => {
        let wordsPerMinute = 100;
        const formattedWords = (info[0].paragraphs.reduce((a, b) => a + b['value'], ''))
        return `${Math.ceil((formattedWords.split(' ').length / wordsPerMinute))} mins`
    }, [info])


    const infographics = [
        {
            title: category.name,
            icon: <Star />,
            subLine: (date ? date : meta.published)
        },
        {
            title: 'Article by',
            icon: <List />,
            subLine: author.name
        },
        {
            title: 'Length',
            icon: <Time />,
            subLine: findTime
        }
    ]





    return (
        <Layout>
            <Seo title={name} />
            <ContentContainerInset>
                <SmallSpacer />
                <SmallSpacer />

                <SmallSpacer />
                <SmallSpacer />

                <SmallSpacer />
                <SmallSpacer />

                <Infographic infoGraphics={infographics} title={article.name} />
                <SmallSpacer />
                <SmallSpacer />
                <div className='pt50 bg-wrapper r6'>
                    <div className='bg-image z-1 scale--up r6'>
                        <div className='rel h100'>
                            {article.featuredVideoUrl ?
                                <ReactPlayer loop={true} width='100%' height='100%' className='h100 w100 bg-image r6' playsinline={true} muted={true} playing={true} url={article.featuredVideoUrl} />
                                :
                                <GatsbyImage alt="" className='h100 w100 r6 ' image={article?.image?.gatsbyImageData} />
                            }
                        </div>
                    </div>
                </div>


                <Spacer />
                <SmallSpacer />
                <TitleList heading={info[0].heading} paragraphs={info[0].paragraphs} />
                <Spacer />
                <Spacer className='m-hide' />
                <SmallSpacer />

            </ContentContainerInset>
            <ContentContainer>
                <FadeInView>
                    <Title link='/news'>
                        <div >
                            <h5>Next</h5>
                        </div>
                        <div className="flex-grow-1" />
                    </Title>
                </FadeInView>

            </ContentContainer>
            <SmallSpacer />
            <SquareScalingBanner image={nextArticle.image} link={`/news/${nextArticle.slug}`} wrapperClassName="al-i-start pl180 parent--hover--enabled">
                <NewsFeatured newsItem={nextArticle} />
            </SquareScalingBanner>
            <Spacer />

        </Layout>
    )
}


export const query = graphql`
   query NewsQuery($id:String,$nextId:String) {
      
        article:datoCmsNews(id: {eq: $id}) {
            name
            slug
            date(formatString: "MM:DD:YYYY")
            featuredVideoUrl
            image {
                gatsbyImageData
            }
            ...NewsFeatured 
            category {
                name
            }
            featuredVideoUrl
            meta {
                published:firstPublishedAt(formatString: "DD.MM.YYYY")
            }
            info:content {
                    heading
                    paragraphs {
                        value
                        name
                        readMoreLink 
                    }
            }
            author {
                ... on DatoCmsTeamMember {
                    id
                    name
            }
        ... on DatoCmsAuthor {
            id
            name
        }
        }
        }
        nextArticle:datoCmsNews(id: {eq: $nextId}) {
            ...NewsFeatured 
        }
    }
`