import React from 'react'

export default function Time() {
    return (
        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.87085 13.3163C11.6207 13.3163 14.6606 10.453 14.6606 6.92097C14.6606 3.38893 11.6207 0.525635 7.87085 0.525635C4.12095 0.525635 1.08105 3.38893 1.08105 6.92097C1.08105 10.453 4.12095 13.3163 7.87085 13.3163Z" stroke="#ff6326 " stroke-linejoin="bevel" />
            <path d="M7.87061 2.07471V6.96127L9.73656 8.71881" stroke="#ff6326 " stroke-linejoin="bevel" />
        </svg>
    )
}
